
.driverSection::before {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    flex-basis:100%;
    content: '';
    /*opacity: 0.3;*/
    z-index: 3;

/*    Gradient to slowly fade in the background image*/
    background: linear-gradient(180deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0.1) 12%, rgba(0,0,0,0.1) 88%, rgba(0,0,0,1) 100%);
}

.driverSection {
    background-image: url('../../public/images/homepage-driver.jpg');
    background-size: 100% 100%;

    position: relative;
    display: flex;
    flex-direction: column;
    padding: 140px 0 140px;

    color: white;
}

.driverSection-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 4;
}

.driverSection-content h3 {
    font-size: 2em;
    margin: 0;
    padding-bottom: 0;
}

.driverSection-content .subtitle {
    font-size: 1.1em;
    margin-bottom: 2em;
    color: #b0b0b0;

    margin-top: 0;
    padding-top: 0;
}


@media only screen and (max-width: 1020px) {
    .driverSection {
        background-size: 300% 100%;
        background-position: center;
    }

        .driverSection-content h3 {
        font-size: 1.5em;
    }
    .driverSection-content .subtitle {
        font-size: 1em;
    }
}
