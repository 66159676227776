.driver-title {
    position: relative;
    /*background: url("/public/images/wave1.webp");*/
    background: url("/public/images/wave2.webp");
    /*background: url("/public/images/wave3.webp");*/
    /*background: url("/public/images/wave4.webp");*/
    /*background: url("/public/images/wave5.webp");*/
    background-size: 100% 100%;

    width: 100%;
    height: 300px;
}

.driver-title::before {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    flex-basis:100%;
    content: '';
    /*opacity: 0.3;*/
    z-index: 3;

    /*    Gradient to slowly fade in the background image*/
    background: linear-gradient(180deg, #000 0%, #0f0f0f0f 12%, #0f0f0f0f 88%, #0f0f0f 100%);
}

.driver-subsection {
    width: 90%;
    max-width: 600px;
    margin-bottom: 50px;
}

.driver-mainContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    min-height: 100vh;

    background: #0f0f0f;
}

.driver-mainContent h1 {
    color: white;

    font-size: 2em;
    margin: 40px 0 0 0;
    padding-bottom: 0;
}

.driver-mainContent h3 {
    color: white;

    font-size: 1.4em;
    margin: 10px 0 50px;
}

.driver-mainContent .subtitle {
    font-size: 1.1em;
    margin-bottom: 2em;
    color: #b0b0b0;

    margin-top: 0;
    padding-top: 0;
}

.driver-howItWorks {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    background: #0f0f0f;
}

.driver-howItWorks .diagram {
    width: 60vw;
    height: 100%;
    max-width: 1000px;
    max-height: 1000px;
    margin: 20px 0 50px 0;

    display: flex;
    flex-direction: row;

    /*justify-content: normal;*/

    color: white;
}

.driver-howItWorks .diagram .column {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    width: 20%;
    margin: 0 5%;
}

.driver-howItWorks .diagram .column .item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    margin: 10px 0;
    padding: 10px 10px;

    width: 100%;
    height: 100%;

    border: 1px solid white;
    border-radius: 10px;
}

.driver-howItWorks .diagram .column .highlighted-item {
    border: 3px solid whitesmoke;
}

.driver-howItWorks .diagram .column .item img {
    padding: 5px 2px 10px 2px;
}


@media only screen and (max-width: 1020px) {
    .driver-howItWorks .diagram {
        flex-direction: column;
    }

    .driver-howItWorks .diagram .column {
        flex-direction: row;

        width: 100%;
        margin: 15px 5px;
    }

    .driver-howItWorks .diagram .column .item {
        margin: 0 15px;
    }
}
