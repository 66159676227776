
.subpage {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    min-height: 100vh;
    height: 100%;
    width: auto;
    background-color: #0f0f0f;
}

.subpage .content {
    display: block;
    /*display: flex;*/
    justify-content: center;
    align-items: center;
    flex-direction: column;
    min-height: 100vh;
    width: 100vw;

    padding: 120px 0;
    max-width: 600px;
    background-color: #0f0f0f;
}

.subpage h2 {
    font-family: "PT Sans", "emoji", serif;
    font-size: 42px;
    color: #fff;
    margin: 0;
    padding-bottom: 20px;
}

.subpage h3 {
    font-family: "PT Sans", "emoji", serif;
    font-size: 22px;
    color: #fff;
    margin: 0;
    padding: 40px 0 20px 0;
}

.subpage p {
    font-family: "PT Sans", "emoji", serif;
    font-size: 24px;
    color: #fff;
    margin: 0;
    padding-bottom: 20px;
}

.subpage a {
    font-size: 24px;
    padding-bottom: 20px;
    margin: 0;
    color: white;
}
