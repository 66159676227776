.faq-question {
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 2rem;

    background-color: #282c34;
    border-radius: 20px;
    padding: 1rem;
}

.faq-question h3 {
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 1rem;
}
.faq-question img {
    width: 100%;
    height: auto;
    border-radius: 20px;
    margin-bottom: 1rem;
}

.faq-question p {
    font-size: 1.2rem;
    margin-bottom: 0.5rem;

    color: #ddd;

    font-weight: lighter;
}

.faq-question a {
    font-size: 1.2rem;

    color: #61dafb;
    text-decoration: underline;
}
