.features {
  /*padding: ;*/
  background-position-y: center;
  /* background-position: ; */
  min-height:100vh;
  height: 100%;
  text-align: center;
  background: #0f0f0f;
}

.features-title {
  position: relative;
  padding: 9rem;
  background: #000 url('../../public/images/features.jpg') center top;
  background-size: 100% 100%;
  background-position-y: center;
  /* background-position: ; */
  /*height:100vh;*/
  width: 100%;
  text-align: center;
  font-size: 24px;
  color:#eee;
}

.features-title::before {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  flex-basis:100%;
  content: '';
  /*opacity: 0.3;*/
  z-index: 3;

  /*    Gradient to slowly fade in the background image*/
  background: linear-gradient(180deg, #000 0%, #0f0f0f0f 12%, #0f0f0f0f 88%, #0f0f0f 100%);
}

.features-title p {
  text-shadow: #0f0f0f ;
}

.features__container {
  display: flex;
  flex-flow: column;
  align-items: center;
  max-width: 1120px;
  width: 90%;
  margin: 0 auto;
  /* height: 10; */
}

.features__wrapper {
  position: relative;
  margin: 50px 0 45px;
}

.features__items {
  margin-bottom: 24px;
  margin-top: 30px;
  /* width: 30%; */
}

.features__item {
  display: flex;
  background-color:rgba(20, 20, 20, 0.8);
  flex: 1;
  margin: 0 1rem;
  border-radius: 10px;

  width: 260px;
  /* height: 240px; */

  /* width: 110px;
  height: 130px; */
}

.features__item__text {
  font-size: 18px;
  line-height: 24px;
  word-wrap: break-word;
  padding-left: 10px;
  padding-right: 10px;
}

.features__item__img {
  padding-top: 10px;
  width: 100%;
  border-radius: 14px;
  /*width: 240px;*/
  /*height: 180px;*/
}


.features__item__link {
  display: flex;
  text-align: center;
  flex-flow: column;
  width: 100%;
  box-shadow: 0 6px 20px rgba(56, 125, 255, 0.17);
  -webkit-filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
  filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
  border-radius: 10px;
  overflow: hidden;
  text-decoration: none;
  color: #bbb;
}

@media only screen and (min-width: 1200px) {
  .features__blog__container {
    width: 84%;
  }
}

@media only screen and (min-width: 1024px) {
  .features__items {
    display: flex;
  }

}

@media only screen and (max-width: 1024px) {
  .features__item {
    margin-bottom: 2rem;
  }

  .features-title {
    padding: 40px 0;
  }


  .features-title p,
  .features-title p {
    text-shadow: #0f0f0f ;
    max-width: 400px;
    margin: 0 auto;
  }
}

